import { Injectable, signal } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LayoutStateService {

  showImageBackground = signal<boolean>(false);

  setShowImageBackground(value: boolean): void {
    this.showImageBackground.update(() => value);
  }

  getShowImageBackground(): boolean {
    return this.showImageBackground();
  }
}
